import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"primary1 fill-height",attrs:{"fluid":""}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{staticClass:"white--text",attrs:{"cols":"12","sm":"8","md":"6","lg":"4","xl":"3"}},[_c(VCard,{attrs:{"color":"primary1","dark":"","flat":"","tile":""}},[_c(VWindow,{model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.length),function(n){return _c(VWindowItem,{key:("card-" + n)},[_c('div',[_vm._v("What is your main fitness goal?")]),_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,{attrs:{"color":"red","height":"200"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,{attrs:{"color":"red","height":"200"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,{attrs:{"color":"red","height":"200"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"6"}},[_c(VCard,{attrs:{"color":"red","height":"200"}})],1)],1)],1)}),1),_c(VCardActions,{staticClass:"justify-space-between"},[_c(VBtn,{attrs:{"text":""},on:{"click":_vm.prev}},[_c(VIcon,[_vm._v("mdi-chevron-left")])],1),_c(VItemGroup,{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.onboarding),callback:function ($$v) {_vm.onboarding=$$v},expression:"onboarding"}},_vm._l((_vm.length),function(n){return _c(VItem,{key:("btn-" + n),scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VBtn,{attrs:{"input-value":active,"icon":""},on:{"click":toggle}},[_c(VIcon,[_vm._v("mdi-record")])],1)]}}],null,true)})}),1),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.next}},[_c(VIcon,[_vm._v("mdi-chevron-right")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }